@import '../styles.scss';

footer{
    width: 100%;
    background: #0096dcc5;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    display: flex;
    justify-content: center;
    .container{
        width: 100%;
        .info{
            max-width: 1400px;
            width: 100%;
            margin: 0 auto;
            height: auto;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
         

            .content{
      
                width: 100%;
                margin: 20px;
                h3, p {
                    color: white;
                }
                h3{
                    font-size: 1.4em;
                    margin: 30px 0;
                    text-align: center;

                }
                
                .featuresContainer{
                    padding: 30px 10px;
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                    text-align: center;

                    p{
                        margin: 20px 0;
                        font-size: 1.2em;
                    }
                  
                }

            }
           

            
        }
        .footerLogo{
            text-align: -webkit-center;
            text-align: center;
            background-color: $primaryDark;
            img{
                max-width: 100%;
                margin: 20px 0;
                width: 300px;
                @media screen and (min-width: $screenmd){
                    width: 350px;
                }
            }   

        }
    
    }

}
@media screen and (min-width: $screenmd){
  footer{
      .container{
          .info{
              flex-direction: row;
              justify-content: space-between;

              .content{
                h3{
                     font-size: 1.7em;
                }
                    .featuresContainer{
                        padding: 30px 10px;
                        display: flex;
                        justify-content: space-between;
                        flex-direction: row;
                        text-align: center;
    
                        p{
                            margin: 40px 0;
                            font-size: 1.3em;
                        }
                      
                    }
                  
              }
          }
      }
  }
}
