@import '../styles.scss';

.container{
    display: flex;
    flex-direction: column;
    color: $primaryDark;
    min-height: 100vh;
    margin-top: 50px;

    .background{
       
        background-repeat: no-repeat;
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        opacity: 0.3;
    }

    .backgroundGradient{
        background:
        linear-gradient(90deg, #fffffff5 0%, rgba(80, 93, 117, 0.81) 100% ),
        url('../../images/silkstart-background.jpg') no-repeat ;
        background-size: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: -3;
    }

    .info{
        max-width: 650px;
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;


        p{
            font-size: 1.17em;
            font-weight: 300;
            margin: 40px 0;
        }
        .btn{
            @include primary-btn;
            line-height: 5em;
        }
  
    }

    .heroImg{
        max-width: 600px;
        width: 100%;
        height: 100%;
        margin: 20px 0;
        margin-left: auto;
        margin-right: auto;
       
        img{
            @include drop-shadow;
        }

    }

    .formContainer{
        width: 100%;
        max-width: 350px;
        min-height: 600px;
        align-self: center;
        margin-left: auto;
        margin-right: auto;
    }

}
@media screen and (min-width: $screenmd){
    .container{
        margin-top: 50px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .info{
            padding: 20px 20px 20px 0;

            h1{
                font-size: 2em;
            }
            p{
                font-size: 1.3em;
            }
        }
  
    }
}

@media screen and (min-width: $screenlg){
    .container{

        .info{
            h1{
                font-size: 3em;
            }
            p{
                font-size: 1.5em;
            }
        }
    }
}
