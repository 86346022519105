@import '../styles.scss';

.container{
    max-width: 350px;
    @include box-shadow;
    padding: 5px;
    text-align: -webkit-center;
    text-align: center;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: auto;
    min-height: 600px;
    background-color: white;
    .titleContainer{
        max-width: 230px;
        place-self: center;
        h1{
            margin: 20px 0;
            font-size: 1.5em;
            font-weight: 500;
            color: $primaryDark;
        }

        p{
            margin: 20px 0;
            font-size: 1.1em;
        }
    }

    form{
        max-width: 300px;
        width: 100%;
        align-self: center;
        height: 335px;

        .input{
            width: 100%;
            height: 50px;
            border: 1px #565F64 solid;
            border-radius: 3px;
            padding: 5px;
            margin: 15px 0;
            margin-left: auto;
            margin-right: auto;
        }

        .errorMsg{
            font-size: .9em;
            font-weight: 100;
            color: $primaryLight;
        }
        .g-recaptcha{
            div{
                width: 100%;
            }
        }
        button{
            @include primary-btn;
            padding: 15px 35px;
            border: none;
            margin: 30px 0 20px 0;
            width: 100%;
        }
    }
        // Submit Form

        .submitContainer{
            .submitTitle{
               font-size: 1.7em;
           }
   
           .successIcon{
               width: 100px;
               margin: 70px 0;
           } 
       }
}
