@import '../styles.scss';

.container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    margin-top: 5px;
    a{

        img{
            max-width: 100%;
            width: 300px;
            @media screen and (min-width: $screenmd){
                width: 350px;
            }
        }        

    }


}

