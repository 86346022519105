@import '../styles.scss';

.container{
    padding: 80px 0;

    h2{
        margin-bottom: 20px;
        text-align: center;
        text-align: -webkit-center;
        color: $primaryDark;
        font-size: 1.4em;
    }

    .info{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        text-align: center;
        margin: 50px 0;
        .wrap{
            width: 500px;
            height: 350px;
            max-width: 100%;
            max-height: 100%;
            justify-content: center;
            margin: 15px 0;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            flex-direction: column;
            .icon{
                max-width: 100px;
                width: 100%;
                height: auto;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 30px;
             
            }

            h4{
                font-size: 1.3em;
                font-weight: bold;
                margin: 10px 0;
            }

            p{
                font-weight: 300;
                font-size: 1.2em;
            }
        }
    }

  
}

@media screen and (min-width: $screenmd){
    .container{
        h2{
            font-size: 1.7em;
        }
  
    }
}

