@import '../styles.scss';

.container{
    min-height: 100vh;
    max-width: 1400px;
    margin: 0 auto;
    width: 100%;
    padding: 0px 20px 20px;
  
}    
